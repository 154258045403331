.card {
  border-radius: 8px;
  overflow: hidden;
  margin: 0;
}

.image-container {
  position: relative;
  width: 100%;
  max-height: 250px; /* altura fija para definir el área visible */
  overflow: hidden; /* oculta la parte de la imagen que sobresale */
}

.card-img {
  width: 100%;
  max-height: 250px;
  object-fit: cover;      /* la imagen cubre el contenedor */
  object-position: bottom; /* enfoca la parte inferior de la imagen */
}

.card-img:hover {
  transform: scale(1.05);
 
}

.card-body {
  padding: 8px; /* Reduce el espacio interno del CardBody */
  padding-top: 12px;
  background: #f8f9fa;
}

.card-open {
  background-color: #ebe990 !important; /* Verde suave para tickets abiertos */
}

.card-closed {
  background-color: #f8f9fa !important; /* Gris claro para tickets cerrados */
}

.card-canceled {
  background-color: #fdbbaa !important; /* Gris claro para tickets cerrados */
  color: #333;
}

.cardTitle {
  font-size: 24px !important;
  font-weight: bold;
  margin: 0;
  color: #333;
  text-align: center;
  margin: 0; /* Quita margen superior e inferior */
  line-height: 1.2; /* Reduce la altura entre líneas */
}

.card-subtitle {
  margin-top: 4px; /* Ajusta el espacio superior */
  margin-bottom: 0; /* Quita el margen inferior */
  font-size: 0.9rem; /* Opcional: reduce el tamaño del subtítulo */
}

.card-text {
  margin-top: 2px; /* Reduce el espacio entre subtítulos y texto */
  margin-bottom: 0; /* Evita espacio adicional */
  font-size: 0.85rem; /* Opcional: ajusta el tamaño del texto */
}

.card .card-category {
  font-size: 16px !important;
  margin: 0;
  font-weight: 600;
  color: #333;
  text-align: center;
  padding-bottom: 0 !important;
}

.card .card-type {
  font-size: 14px !important;
  margin: 0;
  font-weight: 400;
  color: #333;
  text-align: center;
  padding-bottom: 0 !important;
}

.card-car-details {
  font-size: 12px !important;
  margin: 0;
  color: #333;
  text-align: center;
  padding-bottom: 0 !important;
}

.card-footer {
  background-color: #f8f9fa; /* Fondo gris claro */
  color: #6c757d; /* Texto gris oscuro */
  padding: 8px; /* Espaciado interno */
  font-size: 0.7rem; /* Tamaño de fuente más pequeño */
  text-transform: uppercase; /* Texto en mayúsculas */
  text-align: center; /* Centrar el texto */
  border-top: 1px solid #dee2e6; /* Borde superior */
}



/* Indicador flotante */
.status-indicator {
  position: absolute;
  top: 8px; /* Ajusta según el diseño */
  left: 0px; /* Ajusta según el diseño */
  padding:3px 6px;
  width: 'auto';
  height: 20px;
  /* border-radius: 50%; */
  border-end-end-radius: 10px;
  border-start-end-radius: 10px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 1);
  border-width: 0px;
  border-color: white;
  border-style: solid;
  color:#fff;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
}

/* Colores dinámicos para cada estado */
.status-open {
  background-color: #28a745; /* Verde para abierto */
 
}

.status-closed {
  background-color: #6c757d; /* Gris para cerrado */
}

.status-canceled {
  background-color: #dc3545; /* Rojo para cancelado */
}


.references {
  display: flex;
  align-items: center;
  gap: 16px; /* Espaciado entre referencias */
}

.reference-item {
  display: flex;
  align-items: center;
}

.reference-bullet {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
}

.bullet-open {
  background-color: #28a745; /* Verde */
}

.bullet-closed {
  background-color: #6c757d; /* Gris */
}

.bullet-canceled {
  background-color: #dc3545; /* Rojo */
}

.canceled-details {
  background-color: #dc3545;
  padding:3px;
  align-items: center;
  gap: 8px;
  color:white
}
